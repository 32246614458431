import React, { useState, useEffect } from 'react';
import './Home.css';
import Meta from '../components/Meta';
import MainView from '../components/HomeSliderViews/MainView';
import TextView from '../components/HomeSliderViews/TextView';
import SlidingView from '../components/HomeSliderViews/SlidingView';
// import SimpleHeader from '../components/Header/SimpleHeader'


const Home = () => {

  const [currentIndex, setCurrentIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState(0);
  
  // limit each to 20 words
  const firstViewTitle = "What's up and welcome to In The Room (ITR). Our goal is to we believe life is better in person and not on a screen, so we're building a platform to connect people in physical spaces.";
  const secondViewTitle = "In The Room is an in-person app for connecting people in physical spaces. Perfect for networking events, meetups, conferences, festivals, arenas, conference halls, clubs, bars, lounges, and other public places.";
  const thirdViewTitle = "In The Room is an in-person app for connecting people in physical spaces. Perfect for networking events, meetups, conferences, festivals, arenas, conference halls, clubs, bars, lounges, and other public places.";
  const fourthViewTitle = "In The Room is an in-person app for connecting people in physical spaces. Perfect for networking events, meetups, conferences, festivals, arenas, conference halls, clubs, bars, lounges, and other public places.";
  const fifthViewTitle = "In The Room is an in-person app for connecting people in physical spaces. Perfect for networking events, meetups, conferences, festivals, arenas, conference halls, clubs, bars, lounges, and other public places.";

  const views = [
    <MainView onRequestAccess={() => requestAccess()} />,
    <TextView title={firstViewTitle} onRequestAccess={() => handleChangeView(2)} />,
    <TextView title={secondViewTitle} onRequestAccess={() => handleChangeView(3)} />,
    <TextView title={thirdViewTitle} onRequestAccess={() => handleChangeView(4)} />,
    <TextView title={fourthViewTitle} onRequestAccess={() => handleChangeView(5)} />,
    <TextView title={fifthViewTitle} onRequestAccess={() => handleChangeView(6)} />,
  ];

  const requestAccess = () => {
    const inviteCode = window.location.search.split('=')[1];
    // If invite code is present, add it to the URL
    const websiteLink = `https://m.intheroom.social/sign-in?title=Sign%20Up${inviteCode ? `&i=${inviteCode}` : ''}`;
    window.open(websiteLink, '_self');
  };

  const handleChangeView = (newIndex) => {
    console.log('newIndex', newIndex);
    setPrevIndex(currentIndex);
    setCurrentIndex(newIndex);
  };

  useEffect(() => {
    const backgroundColors = ['#000', '#fb9361', '#bfa334', '#ff7eac', '#747474', '#981313']; // Add more colors as needed
    // Add the transition class after the initial load
    document.body.classList.add('bg-transition');
    // Update the body background color
    document.body.style.backgroundColor = backgroundColors[currentIndex] || '#000';

    return () => {
      document.body.classList.remove('bg-transition'); // Cleanup if necessary
    };
  }, [currentIndex]);

  return (
    <>
      <Meta
        title="In The Room | For People Who Like Living Life IRL"
        content="In The Room is an in-person social network for connecting people in physical spaces. Perfect for networking events, meetups, conferences, festivals, arenas, conference halls, clubs, bars, lounges, and other public places."
        url="https://www.intheroom.social"
      />
      {/* <SimpleHeader /> */}
      <div className="container">
        {views.map((view, index) => (
          <SlidingView
            key={index}
            isActive={index === currentIndex}
            isSlidingOut={index === prevIndex}
          >
            {view}
          </SlidingView>
        ))}
      </div>
    </>
  );
};

export default Home;
