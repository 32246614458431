import React, { useEffect, useState } from 'react';
import logo from '../../assets/images/logo.svg';
import ycLogo from '../../assets/images/yc-logo.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'

const MainView = ({ onRequestAccess }) => {
  const [inviter, setInviter] = useState(null);
  const API = window.location.hostname === "localhost"
    ? "http://localhost:8000"
    : "https://8tnulw7wii.execute-api.us-east-1.amazonaws.com/production";


  useEffect(() => {
    const fetchInviter = async () => {
      // Get invite code from URL parameters
      const urlParams = new URLSearchParams(window.location.search);
      const inviteCode = urlParams.get('i');

      if (inviteCode) {
        try {
          const response = await fetch(`${API}/invite/${inviteCode}`);
          const data = await response.json();
          if (data && data.inviter) {
            setInviter(data.inviter);
          }
        } catch (error) {
          console.error("Error fetching invite:", error);
        }
      }
    };

    fetchInviter();
  }, [API]);

  return (
    <>
      <header className="header">
        <img src={logo} alt="Logo" className="logo" />
        {/* <img src="logo.svg" alt="Logo" class="logo" /> */}
      </header>
      <main className="content">
        <div className="title-subtitle-wrapper">
          <h1 className="title">
            CLICK & VIBE <br />
            <span className="highlight">IN THE ROOM</span>
          </h1>
          <p className="subtitle">
            See who's here & what's going on—
            {/* See who's here & what's worth checking out— */}
          {/* Discover new places and meet people—IRL */}
          {/* Explore, vibe, and connect—together IRL */}
            {/* Connect with the World around you—IRL */}
            {/* Connect with people, places and things around you—IRL */}
            {/* Real-time connections in physical spaces */}
            {/* Social  */}
            {/* See who's actually in the room with you<br></br>—right now, in real life */}
            <span className="dashed-text" data-tooltip-id="my-tooltip" data-tooltip-content="In Real Life">
            <b>
              {/* <i> */}
              IRL
              {/* </i> */}
              </b>
            <FontAwesomeIcon className='question-mark' icon={faQuestionCircle} />
            </span>
          </p>
        <Tooltip place="bottom" type="dark" id="my-tooltip" />
          <Tooltip place="bottom" type="dark" id="my-tooltip" />
        </div>
        {inviter && (
          <div className="inviter-container">
            {inviter.image && (
              <img 
                src={inviter.image}
                alt={inviter.name}
                className="inviter-image"
              />
            )}
            <span className="invite-text">
              You've been invited by {inviter.name}
            </span>
          </div>
        )}
        <div className="access-button-wrapper">
          <button className="access-button" onClick={onRequestAccess}>
            {inviter ? "ACCEPT INVITE" : "CONTINUE"}
          </button>
          <div className="access-button-subtitle">
            {inviter ? "Tap accept to continue" : "Press Continue to Tap In"}
          </div>
          <a href='https://www.ycombinator.com/companies/in-the-room' rel="noreferrer" target='_blank'>
            <img src={ycLogo} alt="Y Combinator Logo" className="yc-logo" />
          </a>
        </div>
      </main>
    </>
  );
};

export default MainView;