import React from 'react';
import Meta from "../components/Meta";

const Blog = () => {
  return (
    <>
      <Meta
        title="In The Room | Blog"
        url="https://www.intheroom.social/blog"
      />

      <div>
        <div className="text-container my-5 px-5 px-md-0" style={{ paddingTop: '25vh' }}>
          <div className='blog'>
            <h1 className='title mb-3 text-start' style={{ color: 'white' }}>
              <span className='highlight p-0'>Reimagining Local Social Exploration</span>
            </h1>
            <p className='text-left mb-5'><small>Feb 12, 2025</small></p>
            <p>
                Imagine walking into a space already knowing you're open to meeting new people. You’re expecting it, ready for it, and
              excited about the possibilities. That’s the mindset and culture we want to create with <strong>In The Room</strong>.
            </p>

            <p>
              Much like the vibe at a Soho House—where being part of a social club encourages friendliness and connection—
              <strong>In The Room</strong> is designed to foster the same energy. It’s built on the idea of being welcoming and creating
              meaningful encounters wherever you are.
            </p>

            <h2>Addressing a Modern Need</h2>

            <p>In today’s world, especially in the U.S., there’s a growing desire for genuine in-person connections:</p>

            <ul>
              <li><strong>Hobbyists</strong> want to meet others who share their interests.</li>
              <li><strong>Singles</strong> are tired of endless swiping on dating apps; they want real-life encounters with people who meet their standards and values.</li>
              <li><strong>Professionals</strong> are looking to network with like-minded peers who are on their level.</li>
            </ul>

            <p>
              The challenge is knowing <em>where</em> to go to find these people. That’s where <strong>In The Room</strong> comes in—not just
              as a tool for socializing but as a guide to help you navigate the spaces that align with your goals, whether it’s dating,
              hobbies, or professional networking.
            </p>

            <h2>A Smarter Way to Connect</h2>

            <p>Here’s what makes <strong>In The Room</strong> so unique:</p>

            <ol>
              <li>
                <strong>Personalized Recommendations:</strong>
                <ul>
                  <li>If you’re dating, it guides you to places where people who match your standards are likely to be.</li>
                  <li>If you’re into hobbies, it guides you to places where people who match your standards are likely to be.</li>
                  <li>If you’re networking, it guides you to places where people who match your standards are likely to be.</li>
                </ul>
              </li>
              <li>
                <strong>Community Over Isolation:</strong>
                It’s not just about meeting one person—it’s about finding your people, your community. Whether you’re in Colombia, the U.S.,
                or anywhere in the world, the platform helps you discover the places and groups that resonate with you.
              </li>
              <li>
                <strong>A Digital Social Club:</strong>
                Every place becomes part of the club. With <strong>In The Room</strong>, the world transforms into a network of social opportunities,
                effectively creating a global, remote social club. Whether the app partners with venues or not, it fosters a community-driven
                experience, turning any space into a hub for connection.
              </li>
            </ol>

            <h2>Making Connection Effortless</h2>

            <p>
              The beauty of <strong>In The Room</strong> is its simplicity and inclusivity. It removes the guesswork and anxiety of going out
              by showing you exactly where your people are. It’s not about exclusivity or singling people out—it’s about empowering everyone
              to find their community, their tribe, wherever they are.
            </p>

            <p>
              This approach turns every city, every venue, into an opportunity for connection, transforming how we think about socializing.
              <strong>In The Room</strong> isn’t just an app; it’s a movement to make the world smaller, friendlier, and more connected.
            </p>

            <p><strong>That’s not just cool—it’s revolutionary. 🌍🎉</strong></p>
          </div>
          <div className='blog'>
            <h1 className='title mb-3 text-start' style={{ color: 'white' }}><span className='highlight p-0'>"Dating Mode"</span></h1>
            <p className='text-left mb-5'><small>Jan 7, 2025</small></p>
            <p>
              When we started, the idea was to create an app for everything—connecting professionally, making friends, and even dating.
              It was ambitious, but we thought it could work. Then, we decided to narrow our focus to dating. At first, it made sense:
              pick one thing and do it well.
            </p>

            <p>
              But what I’ve come to realize is this: it’s more effective to stay open and flexible while giving users the option to
              enable <strong>"dating mode."</strong>
            </p>

            <h2>Why "Dating Mode" Works</h2>

            <p>The idea is simple. The app is for everyone—those looking to network, make friends, or date—but dating becomes an optional mode you can turn on. Here's how it works:</p>

            <ul>
              <li>If you're not interested in dating, you won't see or interact with dating features.</li>
              <li>If you are, turning on dating mode allows you to see others who also have it enabled.</li>
              <li>No mismatched intentions—only mutual interest.</li>
            </ul>

            <p>
              This keeps the app versatile and inclusive without forcing a single focus on users. It’s not just about dating; it’s about
              giving people control over how they connect.
            </p>

            <h2>A Smarter Way to Connect</h2>

            <p>
              With dating mode, you can also personalize your experience. Not only will you see compatible matches, but you’ll also
              discover where they’re likely to be. This adds a real-world layer to online connections, making them feel more natural and
              exciting.
            </p>

            <h2>Staying Open, Staying Effective</h2>

            <p>
              This approach strikes a balance. By being open to all types of connections but allowing users to activate dating mode,
              the app stays relevant to a broader audience while still serving those who are dating-focused. It’s not about doing less—
              it’s about doing more with intention.
            </p>

            <p>
              That’s the direction we’re heading: an app that adapts to you, making it easier to connect in the way that works best for your life.
            </p>
          </div>

          <div className='blog'>
            <h1 className='title mb-3 text-start' style={{ color: 'white' }}><span className='highlight p-0'>What is In The Room?</span></h1>
            <p className='text-left mb-5'><small>Dec 24, 2024</small></p>
            <p>In The Room is a members-only, location-based social network designed to help you vibe with people who are literally in the same room as you. Imagine being in a crowded place and wondering, "Who’s here?"—we answer that question, providing seamless ways for you to connect.</p>
            <p>Our current focus is on dating, but we’re expanding to include friendships and professional networking. Forget dating apps and overpriced $2K+ social clubs that lead nowhere. Instead, go outside, vibe, and click with people at the places you already love to visit.</p>
            <h2>Where can you use it?</h2>
            <p>Anywhere people gather! Whether it’s restaurants, bars, clubs, lounges, events, or public spaces like Miami Beach, In The Room makes it easy to discover and connect with others in real-time.</p>
          </div>
          <div className='blog mt-5'>
            <h1 className='title mb-3 text-start' style={{ color: 'white' }}><span className='highlight p-0'>Not Another F'n Dating App</span></h1>

            <p className='text-left mb-5'><small>Dec 18, 2024</small></p>

            <p className='display-6'><i>Okay, look. We didn’t set out to create another dating app—that’s just not what we wanted to do.</i></p>

            <p>Honestly, we didn’t even want to touch dating. It felt played out, trash even, plus we didn’t want to get stuck in that lane. But here’s the thing: this is what people keep saying they want. They’re asking for it because dating apps aren’t hitting the way they need to hit today.</p>
            <p>
              Let’s break it down:
              <ol>
                <li><b>The Swipe Trap:</b> You match, but it doesn’t go anywhere. Endless swiping, zero action.</li>
                <li><b>Weirdos & Catfish:</b> Talking to someone? They turn out weird—or worse, a catfish. It’s still a thing.</li>
                <li><b>Commitment Phobia:</b> Some people love the chase but bail at the first sign of something real.</li>
                <li><b>Chatting Forever:</b> Conversations drag on, and no one actually meets up.</li>
              </ol>
            </p>
            <p>Here’s the truth: you’re more likely to click with someone who’s already in the same room as you. At a concert? They probably vibe with your music. At the same restaurant? Shared taste. It’s simple, yet most apps ignore it.</p>

            <p>But let’s be real—approaching someone in person is hard. Everyone’s guarded. Like my friend Adrienne Reed says, it’s like an eighth-grade dance—guys on one side, girls on the other. Unless someone gives you the look, you’re stuck.</p>

            <p>We’re here to fix that. Not just another dating app, but a way to bring real-life connections back.</p>

            <p>“In The Room” has been in the works for years—since 2013, actually. It started as a networking concept, but let’s face it: networking is boring. People want something real, dynamic, human.</p>

            <p>We’re taking what works in dating apps (the basics: who you are, what you want) and finally bridging the gap to real life. This isn’t about staying in the app; it’s about stepping out into the world.</p>

            <p>We didn’t plan to go this route, but here we are—and we’re going to make it work.</p>

            <figure>
              <blockquote class="blockquote">
                <p>André Woodley Jr.</p>
              </blockquote>
              <figcaption class="blockquote-footer">
                Founder, In The Room
              </figcaption>
            </figure>
          </div>
          <div className='blog mt-5'>
            <h1 className='title mb-3 text-start' style={{ color: 'white' }}><span className='highlight p-0'>The White Paper</span></h1>
            <p className='text-left mb-5'><small>Oct 20, 2024</small></p>

            <p>We have dedicated over a decade to refining our vision of a hyper-local social networking platform that tackles core issues within the social interaction space, both online and offline. This white paper elucidates the motivations behind our pursuit, outlines the current social landscape, and delineates our plans to revolutionize how people connect through our solution, "In The Room."</p>

            <h2>Our Vision</h2>
            <p>We believe a shift is imminent—a new social platform centered on location that integrates our online identities into offline spaces, making connecting easier. This approach aims to foster genuine connections and bridge the gap between our online personas and physical interactions. Here’s why:</p>
            <ul>
              <li><b>Generational Shift:</b> With 70% of Gen Z open to sharing their location compared to just 7% of Millennials, there's a trend toward using technology more transparently to foster online-to-offline social connections.</li>
              <li><b>Global Connectivity:</b> With 3 billion people sharing their lives online, and over 1 billion having professional accounts like LinkedIn, the potential for integrating these identities into physical spaces is immense.</li>
            </ul>

            <h2>The Current Landscape</h2>
            <p>Despite being able to connect with billions of people online, there is a global pandemic of loneliness and depression. Our current social dynamics present several challenges:</p>
            <ul>
              <li><b>Anti-social Culture:</b> Factors such as anxiety, cliquey behavior, and reliance on social media for initial judgments hinder meaningful connections.</li>
              <li><b>Broken Outgoing Experiences:</b> Planning social outings can be frustrating due to poor information, availability, and cumbersome entry processes.</li>
              <li><b>Isolation of Affluence:</b> Affluent individuals often face social isolation, struggling to form authentic connections as they are viewed as opportunities for personal gain.</li>
              <li><b>Global Loneliness:</b> The increase in reported loneliness and depression underscores the need for more meaningful social interactions.</li>
              <li><b>Language Barriers:</b> As globalization increases, language barriers remain a significant hurdle to connecting meaningfully in new environments.</li>
            </ul>

            <h2>The Role of Social Clubs</h2>
            <p>Social clubs like country clubs and fraternities have proven successful in building community bonds. Clubs like Soho House, which integrate dining and nightlife, showcase the potential for diverse social experiences. These models of engagement inspire our approach to creating varied social interactions under one umbrella.</p>

            <h2>Our Journey</h2>
            <p>We have pursued the idea of "In The Room" and other hyper-local social networking solutions for over ten years, releasing iterations of "In The Room" since 2014. Despite believing that big companies would adopt this concept, the reality is that these companies focus on advertisements and keeping users inside the app. Our persistence stems from a strong belief that this idea is more than just a feature or one-off app; it has the potential to grow to the level of Meta or Google.</p>

            <h2>In The Room: A Proposed Solution</h2>
            <p>"In The Room" is our solution to the aforementioned social challenges, designed to enhance real-world connections through a hyper-local approach. We built "In The Room," a hyper-local app that helps people connect in physical spaces. It's perfect for networking events, meetups, conferences, festivals, arenas, conference halls, clubs, bars, lounges, and other public spaces. Key features include:</p>
            <ul>
              <li><b>Seamless Integration:</b> Users can discover events, join experiences, check in smoothly, meet new people easily, and manage interactions all within one platform.</li>
              <li><b>Localized Focus:</b> Initially focusing on providing a seamless experience for specific communities to refine our offerings and ensure effectiveness before expanding.</li>
              <li><b>Real-Time Connection:</b> "In The Room" leverages real-time data to help people connect with others in the same physical space, making it easier to meet new people at events, meetups, conferences, festivals, arenas, clubs, bars, lounges, and other public spaces.</li>
            </ul>

            <h2>Conclusion</h2>
            <p>"In The Room" represents our attempt to connect the world by creating a platform that enhances real-world connections in an increasingly digital age. Our vision is to foster genuine connections, starting with a core community and scaling to broader markets, ultimately bridging the gap between our online identities and physical interactions.</p>

            <p>This is more than just an app; it's a movement to improve the way people connect and interact in the real world. We believe in its potential to foster genuine connections and make a significant impact on how we engage socially in the digital era.</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog;
